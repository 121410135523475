import { StockStatus } from "./types/gql/product/product-by-slug.type";
import axios from "axios";
import { type ClassValue, clsx } from "clsx";
import { NextApiResponse } from "next";
import { twMerge } from "tailwind-merge";
import { YoastJsonInterface } from "./types/rest-api/seo/yoast.type";
import { ObserveElementFunction } from "./types/utils.type";
import { WooGetResponse } from "./Woocommerce/WooCommerceRApi";
import { ProductReviewInterface } from "./types/rest-api/product/review.type";
import { CreateOrderResponseInterface } from "./types/rest-api/order/create-order.type";
import { getPageContentCode } from "./hooks/useGetPageCode";

const baseUrl = process.env.NEXT_PUBLIC_WORDPRESS_URL as string;

export const getReviews = async (id: number): Promise<WooGetResponse<ProductReviewInterface[]>> => {
  const res = await fetch(`/api/products/review?id=${id}`);
  return res.json();
};

/**
 * 是否开启库存管理，如果开启，则库存为stockQuantity字段的值，不开启，则无视stockQuantity字段库存无限
 * @param stock
 * @param quantity
 * @returns
 */
export const isManagesStock = (stock: StockStatus, quantity?: number | null) => {
  return !(stock === "IN_STOCK" && quantity === null);
};

export const headers = {
  "Access-Control-Allow-Origin": "*",
  // "Access-Control-Allow-Origin": "https://qq.pinshop.com/,https://nextorchstore.com/,https://admin.nextorchstore.com/",
  "Access-Control-Allow-Methods": "GET,OPTIONS",
  // "Access-Control-Allow-Headers": "Content-Type",
  "Access-Control-Allow-Headers": "*",
  "Access-Control-Allow-Credentials": "true",
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function sleep(time: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
}

export const setCorsHeaders = (res: NextApiResponse) => {
  res.setHeader("Access-Control-Allow-Origin", "*"); // 注意：这允许任何源
  res.setHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT, PATCH, DELETE");
  res.setHeader("Access-Control-Allow-Headers", "*");
  res.setHeader("Access-Control-Allow-Credentials", "true");
};

export function observeElementIntersection(
  className: string,
  enter: ObserveElementFunction,
  leave?: ObserveElementFunction,
) {
  const elements = document.querySelectorAll(`.${className}`);

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        enter(entry.target);
      } else {
        leave && leave(entry.target);
      }
    });
  });

  elements.forEach((element) => {
    observer.observe(element);
  });
}

// 设置html的overflow
export function setOverflow(overflow: "hidden" | "") {
  document.documentElement.style.overflow = overflow;
}

// 防抖函数
export function debounce(fn: Function, delay: number) {
  let timer: any = null;
  return (...args: any[]) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      // @ts-ignore
      fn.apply(this, args);
    }, delay);
  };
}

// 传递多个数组然后传递字段根据字段去重
export function uniqueArray(arr: any[], key: string): any[] {
  const map = new Map();
  const result = [];
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    if (!map.has(item[key])) {
      map.set(item[key], true);
      result.push(item);
    }
  }
  return result;
}

// 用于拆分wordpress产品图片，并重组
export const splitImgSrc = (link: string, splicing: string) => {
  if (link && link.includes("/")) {
    const splitLink = link.split("/");
    const last = splitLink.at(-1)?.split(".");
    const fileName = last?.[0] + splicing + "." + last?.at(-1);
    const newLink = splitLink.slice(0, splitLink.length - 1);
    newLink.push(fileName);
    return newLink.join("/");
  }
  return link;
};

// 计算产品折扣
export const getDiscount = (price: string, regularPrice: string) => {
  const normalPrice = convertPriceToNumber(price);
  const RegularPrice = convertPriceToNumber(regularPrice);
  if (price && regularPrice) {
    const discount = Math.round(((normalPrice - RegularPrice) / normalPrice) * 100);
    return discount > 0 ? discount + "%" : 0;
  }
  return 0;
};

export const isDev = process.env.NODE_ENV === "development";

export function convertPriceToNumber(priceString: string): number {
  if (!priceString) return 0;
  if (isNaN(+priceString[0])) {
    priceString = priceString.replace(/[^0-9.]/g, "");
  }
  return parseFloat(priceString);
}

export function formatPrice(price: string) {
  const newPrice = convertPriceToNumber(price);
  return newPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getYoastSeoHead(url: string): Promise<{
  status: number;
  json: YoastJsonInterface;
}> {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/yoast/v1/get_head?url=${url}`);
    resolve(await res.json());
  });
}

export const cApiUrl = isDev ? "http://localhost:" + process.env.NEXT_PUBLIC_PROD : process.env.NEXT_PUBLIC_SITE_URL;

// 处理后台数据 需要innerHTML的参数
export const handlerInnerHtml = (html: string): string => {
  if (!html) return html;
  return html
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/&quot;/g, '"')
    .replace(/&#8216;/g, "‘")
    .replace(/&#8217;/g, "’")
    .replace(/&#8211;/g, "–")
    .replace(/&nbsp;/g, " ");
};

// 判断是否是中文
export function isChinese(str: string) {
  const reg = /[\u4e00-\u9fa5]/;
  return reg.test(str);
}

// 处理中文slug
export const handlerPathSlug = (slug: string): string => {
  return isChinese(decodeURIComponent(slug)) ? decodeURIComponent(slug) : slug;
};

// 提取youtube视频链接
export const getYoutubeLink = (url: string) => {
  const reg = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(reg);
  return match && match[7].length === 11 ? match[7] : "";
};

// 提取vimeo视频链接id
export const getVimeoLink = (url: string): string => {
  const regex = /vimeo\.com\/(\d+)/;
  const match = url.match(regex);
  return match ? match[1] : "";
};

// 获取当前地址的url参数
export const getUrlParams = () => {
  return new URLSearchParams(window.location.search);
};

// 获取当前站点类型
export const getSiteTypeIsTob = async () => {
  let type = "tob";
  try {
    const res = await axios.get("https://softcdn.pintreel.com/api/pinshop/account/get_site_type", {
      params: {
        domain: new URL(process.env.NEXT_PUBLIC_WORDPRESS_URL as string).hostname,
      },
    });
    if (res.status !== 200 || res.data.code !== 1) {
      type = "tob";
    } else {
      type = res.data.data.site_type;
    }
  } catch (e) {
    type = "tob";
  }
  return type === "tob";
};

export const replaceCdnDomain = (url: string) => {
  const cdn = "https://nalide.cdn.pinshop.com";
  if (!cdn) {
    return url;
  }
  if (url?.startsWith(process.env.NEXT_PUBLIC_WORDPRESS_URL as string)) {
    return url.replace(process.env.NEXT_PUBLIC_WORDPRESS_URL as string, cdn);
  } else {
    return url;
  }
};

// 根据价格，货币最小单位数量级生成价格
export const byMinorUnit = (price: number | string | undefined, minor_unit: number) => {
  price = price === undefined ? 0 : price;
  return parseInt(String(+price)) / Math.pow(10, minor_unit);
};

export const getCommonConfig = async () => {
  try {
    const res = await axios.get<any>(
      `${process.env.NEXT_PUBLIC_WORDPRESS_URL as string}/wp-json/ps-config/v1/front/config/get`,
    );
    if (res.data.code !== 1) {
      return null;
    }
    return res.data.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const generateAirwallexLink = async (data: CreateOrderResponseInterface) => {
  const orderId = data.id;
  // 获取Airwallex支付链接
  const result = await fetch(
    `${process.env.NEXT_PUBLIC_WORDPRESS_URL as string}/wp-json/ps-pay/v1/airwallex/process-order-pay`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order_id: orderId,
        payment_method: "airwallex_card",
      }),
    },
  ).then((r) => r.json());

  if (result && result.result === "success") {
    return result;
  } else {
    return null;
  }
};

export const getAverageRating = async (product_id: number | string) => {
  try {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/ps-product-qa/v1/front/review/get-average-rating?product_id=${product_id}`,
    );
    if (data.code === 1) {
      return data.data;
    }
    return null;
  } catch (e) {
    console.error("getAverageRatingError", e);
    return null;
  }
};

export const objectToQueryParams = (obj: { [key: string]: any }): string => {
  return Object.entries(obj)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&");
};

export const getPageCodeById = async (page_id: number | string) => {
  if (!page_id) return undefined;
  // 构造一个动态的正则表达式来匹配页面内容
  const regEx = new RegExp(`<script id="${page_id}" type="application\\/json">([\\s\\S]*?)<\\/script>`);
  let parseData;
  const page_code = await getPageContentCode(page_id);
  const match = page_code.match(regEx);
  if (match) {
    const jsonDataString = match[1].trim();
    try {
      const data = JSON.parse(jsonDataString);
      const translateData = Array.isArray(data) ? data : [data];
      parseData = translateData || null;
    } catch (err) {
      console.log("err:", err);
      parseData = null;
    }
  } else {
    parseData = null;
  }
  return parseData;
};


//发送重置密钥
export const submitResetPassword = async (email: string) => {
  try {
    const res = await axios<any>(`${baseUrl}/wp-json/ps-password/v1/front/config/send_reset_key`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { email },
    });
    return res.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

//重置密码
export const submitResetPasswordByKey = async (form: any) => {
  try {
    const res = await axios<any>(`${baseUrl}/wp-json/ps-password/v1/front/config/reset_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: form,
    });
    return res.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};
