import { InferType, object, string } from "yup";
import { AddToCartProps, CartResponse, CartTotals, RemoveCartItemProps, SetLoading } from "@/lib/hooks/cart/@types";
import { toast } from "sonner";
import CoCartAPI, { CoCartOptions } from "@cocart/cocart-rest-api";
import { useShoppingCartStore } from "@/store/shoppingCart.store";
import { useUserStore } from "@/store/user.store";

type CouponResponse = {
  code: number;
  data: null | CartResponse.RootObject;
  msg: string;
  time: number;
};

export const addToCartSchema = object({
  id: string().required(),
  quantity: string().required(),
  variation: object(),
  item_data: object(),
});

export const removeCartItemSchema = object({
  item_key: string().required(),
});

export const updateCartItemSchema = object({
  item_key: string().required(),
  quantity: string().required(),
});

// 合并cart_key 到url中
const mergeCartKey = (url: string) => {
  return url;
};

export const useCart = () => {
  const { setCartList, setCartCount } = useShoppingCartStore();
  const { userInfo } = useUserStore();
  const CoCart = (version?: CoCartOptions["version"]) => {
    const user = userInfo as any;
    return new CoCartAPI({
      version: version ? version : "cocart/v2",
      url: process.env.NEXT_PUBLIC_WORDPRESS_URL as string,
      consumerKey: user?.username || "",
      consumerSecret: user?.password || "",
      axiosConfig:{
        headers: {
          Authorization: `Bearer  ${user?.token || window.btoa(`${user?.username}:${user?.password}`)}`,
          "Content-Type": "application/json",
        }
      }
    });
  };

  // 添加产品到购物车
  const addToCart = async (props: AddToCartProps, setLoading?: SetLoading) => {
    try {
      setLoading?.(true);
      // 检验传入参数
      const validate: InferType<typeof addToCartSchema> = await addToCartSchema.validate(props, { strict: true });
      // 发送请求
      const result = (await CoCart().post(mergeCartKey("cart/add-item"), validate)).data as CartResponse.RootObject;
      toast.success("Added to cart!");
      setCartList(result);
      return result;
    } catch (e: any) {
      console.error(e);
      toast.error(e?.message || "Add failed!");
    } finally {
      setLoading?.(false);
    }
  };

  // 从购物车删除某个产品
  const removeCartItem = async (props: RemoveCartItemProps, setLoading?: SetLoading) => {
    try {
      setLoading?.(true);
      // 检验传入参数
      const validate: InferType<typeof removeCartItemSchema> = await removeCartItemSchema.validate(props, {
        strict: true,
      });
      // 发送请求
      const result = (
        await CoCart().delete(mergeCartKey(`cart/item/${validate.item_key}`), {
          return_status: false,
        })
      ).data as CartResponse.RootObject;
      setCartList(result);
      return result;
    } catch (e: any) {
      console.error(e);
    } finally {
      setLoading?.(false);
    }
  };

  // 清空整个购物车
  const clearCart = async (setLoading?: SetLoading) => {
    try {
      setLoading?.(true);
      await CoCart().post(mergeCartKey("cart/clear"), {
        keep_remove_items: false,
      });
      setCartList(null);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      setLoading?.(false);
    }
  };

  // 获取购物车内容
  const getCart = async () => {
    try {
      const result = (await CoCart().get(mergeCartKey("cart"))).data as CartResponse.RootObject;
      setCartList(result);
      return result;
    } catch (e) {
      // console.error(e);
      return null;
    }
  };

  // 获取购物车的数量
  const getCartCount = async () => {
    try {
      const count = (
        await CoCart().get(mergeCartKey("cart/items/count"), {
          removed_items: false,
        })
      ).data as number;
      setCartCount(count || 0);
      return count;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  // 计算购物车总价格
  const getCartTotal = async () => {
    try {
      return (
        await CoCart().get(mergeCartKey("cart/totals"), {
          html: false,
        })
      ).data as CartTotals.RootObject;
    } catch (e) {
      console.error(e);
    }
  };

  // 根据运费计算购物车总价格
  const getCartTotalByShipping = async (
    shipping: {
      total: string;
    },
    setLoading?: SetLoading
  ) => {
    try {
      const totals = await getCartTotal();
      if (totals) {
        const { subtotal } = totals;
        return {
          ...totals,
          shipping_total: shipping.total,
          total: (+subtotal + +shipping.total - +totals.discount_total).toString(),
        } as CartTotals.RootObject;
      }
      return null;
    } catch (e) {
      return null;
    } finally {
      setLoading?.(false);
    }
  };

  // 更新购物车数量
  const updateItemCount = async (props: InferType<typeof updateCartItemSchema>, setLoading?: SetLoading) => {
    try {
      setLoading?.(true);
      await updateCartItemSchema.validate(props, { strict: true });
      const result = (
        await CoCart().post(mergeCartKey(`cart/item/${props.item_key}`), {
          quantity: props.quantity,
          return_cart: true,
        })
      ).data as CartResponse.RootObject;
      setCartList(result);
      return result;
    } catch (e) {
      console.error(e);
    } finally {
      setLoading?.(false);
    }
  };

  // 应用优惠券
  const applyCoupon = async (code: string, setLoading?: SetLoading) => {
    try {
      setLoading?.(true);
      const result = (
        await CoCart().post(mergeCartKey(`cart/app-coupon`), {
          coupon: code,
        })
      ).data as any;
      if (result.code !== 1 || result.data === null) {
        toast.error(result.msg);
        return;
      } else {
        toast.success("Applied coupon!");
      }
      setCartList(result?.data);
      return result;
    } catch (e) {
      console.error(e);
    } finally {
      setLoading?.(false);
    }
  };

  // 移除优惠券
  const removeCoupon = async (code: string, setLoading?: SetLoading) => {
    try {
      setLoading?.(true);
      const result = (
        await CoCart().post(mergeCartKey(`cart/remove-coupon`), {
          coupon: code,
        })
      ).data as any;
      if (result.code !== 1 || result.data === null) {
        toast.error(result.msg);
        return;
      }
      setCartList(result?.data);
      return result;
    } catch (e) {
      console.error(e);
    } finally {
      setLoading?.(false);
    }
  };

  // 购物车计算
  const calculateCart = async (chosen_method: string, setLoading?: SetLoading) => {
    try {
      setLoading?.(true);
      const result = (
        await CoCart().post(mergeCartKey(`cart/calculate`), {
          chosen_method: chosen_method,
        })
      ).data as CartResponse.RootObject;
      setCartList(result);
      return result;
    } catch (e) {
      console.error(e);
    } finally {
      setLoading?.(false);
    }
  };

  return {
    applyCoupon,
    getCartTotalByShipping,
    addToCart,
    removeCartItem,
    clearCart,
    getCart,
    getCartCount,
    getCartTotal,
    updateItemCount,
    removeCoupon,
    calculateCart,
  };
};
